import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import intl from "react-intl-universal";
import { RouteComponentProps } from "react-router";
import LoadingButton from "../../components/LoadingButton";
import { AuthenticationHelper } from "../../utils/authenticationHelper";
import {
  createBookingUrlLink,
  getTargetForBookingUrl,
} from "../../model/model";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      background: "#fff",
    },
    root: {
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 98,
      maxWidth: theme.maxWidth,
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 30,
        paddingRight: 30,
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    title: {
      textAlign: "center",
    },
    text: {
      marginTop: 20,
      paddingBottom: 20,
      fontSize: theme.fontSizes.mediumFont,
      textAlign: "center",
    },
    logoIconPng: {
      maxWidth: 400,
      width: "80vw",
    },
    partnersButton: {
      minWidth: 168,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        flex: 1,
      },
    },
  }),
);

export interface Props extends RouteComponentProps<void> {}

export default function ShowFamilyBonusCardPage(props: Props) {
  const { match } = props;
  const { params } = match;

  const theme = useTheme();
  const classes = useStyles(theme);

  // @ts-ignore
  const cardNumber = params.card_number;

  return (
    <div className={classes.background}>
      <Grid
        container
        className={classes.root}
        direction={"column"}
        alignItems={"center"}>
        <Grid item>
          <img
            src="/family-bonus-card-logo.png"
            className={classes.logoIconPng}
          />
        </Grid>
        <Grid item>
          <Typography variant={"h2"} className={classes.title}>
            {intl.get("show_family_bonus_card.title")}
          </Typography>
        </Grid>
        <Grid item>
          <div className={classes.text}>
            {intl.get("show_family_bonus_card.placeholder_text")}
          </div>
          <div className={classes.text}>
            {intl.get("show_family_bonus_card.bookmark_text")}
          </div>
          <div className={classes.text}>
            {intl.get("show_family_bonus_card.partners_hint")}
          </div>
        </Grid>
        <Grid item>
          <Button
            color={"primary"}
            className={classes.partnersButton}
            onClick={() => {
              window.open(
                "https://familienausflug.info/ausflug-mit-kindern/family-bonus-card-partner",
                "_blank",
                "noopener,noreferrer",
              );
            }}>
            {intl.get("show_family_bonus_card.show_partners_button")}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
